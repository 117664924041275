import config from '../../src/config'
import { PlainSearchParameters } from 'algoliasearch-helper'
import algoliasearch from 'algoliasearch'
import algoliasearchHelper from 'algoliasearch-helper'

function getUserToken() {
  if (typeof window === 'undefined') {
    return 'server'
  }
  let token = localStorage.getItem('algoliaUserToken')
  if (!token) {
    token =
      Math.random().toString(36).substring(2) +
      Math.random().toString(36).substring(2)
    localStorage.setItem('algoliaUserToken', token)
  }
  return token
}

/** @firescoutMockFn algolia.createAlgoliaHelper */
export default async function createAlgoliaHelper(
  index: string,
  options: PlainSearchParameters
) {
  const client = algoliasearch(
    config.modules.algolia.applicationId,
    config.modules.algolia.apiKey,
    {
      headers: {
        'X-Algolia-UserToken': getUserToken()
      }
    }
  )
  const helper = algoliasearchHelper(client, index, {
    attributesToHighlight: [],
    ...options
  })

  /**
   * We want to distinguish between server and client request and bots to track where our
   * queries come from so we add a analytics tag in our request
   */
  let isBot = false
  if (typeof window !== 'undefined')
    isBot = /bot|googlebot|crawler|spider|robot|crawling/i.test(
      navigator.userAgent
    )
  const isFrontend = typeof window !== 'undefined'
  const tags: string[] = []
  if (isBot) tags.push('frontend-bot')
  if (!isBot && isFrontend) tags.push('frontend-client')
  if (!isFrontend) tags.push('frontend-server')

  if (isFrontend && window.location.pathname.includes('/pdp/')) {
    tags.push('pdp')
  }

  helper.setQueryParameter('analyticsTags', tags)

  // monkeypatch "setQueryParameter"
  helper.setQueryParameter = (name, value) => {
    const proto = Object.getPrototypeOf(helper)
    if (name !== 'analyticsTags')
      return proto.setQueryParameter.bind(helper)(name, value)
    return proto.setQueryParameter.bind(helper)(name, [
      ...(value as any),
      ...tags
    ] as any)
  }

  return helper
}
