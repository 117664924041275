import aa, { InsightsMethodMap, InsightsEvent } from 'search-insights'
import { addRule } from 'redux-ruleset'
import config from 'config'
import getCookieByName from 'utils/getCookieByName'
import { WidgetClick } from 'theme/molecules/ProductWidget/events'
import { isLusiniPluginEnabled } from '../utils'

function getConsentStatus() {
  if (getCookieByName('OptanonAlertBoxClosed') === undefined) return 'UNKNOWN'
  return getCookieByName('OptanonConsent')?.includes('C0004:1')
    ? 'ACCEPTED'
    : 'REJECTED'
}

aa('init', {
  appId: config.modules.algolia.applicationId,
  apiKey: config.modules.algolia.apiKey,
  useCookie: true
})

// only push to aa if lusini plugin is not enabled
const pushAa = (evt: keyof InsightsMethodMap, evtObject: InsightsEvent) => {
  if (!isLusiniPluginEnabled) {
    aa(evt, evtObject)
  }
}

addRule({
  id: 'algolia/CLICK_PRODUCT',
  output: '#algolia-tracking',
  target: 'ProductWidget/WIDGET_CLICK',
  condition: (action) =>
    !!action.meta.algoliaQueryID && getConsentStatus() === 'ACCEPTED',
  consequence: (action) => {
    const algoliaCookie = getCookieByName('_ALOGLIA')
    pushAa('clickedObjectIDsAfterSearch', {
      index: config.index.products,
      eventName: 'Click Product',
      queryID: action.meta.algoliaQueryID ?? '',
      ...(algoliaCookie && {
        userToken: algoliaCookie
      }),
      objectIDs: [action.payload.sku],
      positions: [action.meta.listPosition]
    })
  }
})

addRule({
  id: 'algolia/ADD_CART_PRODUCT',
  output: '#algolia-tracking',
  target: 'CART_ADD_ITEM',
  condition: (_, { context }) => {
    const clickAction: WidgetClick | null = context.get('clickAction')
    if (!clickAction) return false
    return (
      !!clickAction.meta.algoliaQueryID && getConsentStatus() === 'ACCEPTED'
    )
  },
  addWhen: function* (next, { context }) {
    yield next(
      ['ProductWidget/WIDGET_CLICK', 'navigation/LOCATION_CHANGED'],
      (action) => {
        if (action.type === 'ProductWidget/WIDGET_CLICK') {
          context.set('clickAction', action)
        } else if (action.payload.pathname.includes('/pdp/')) {
          return true
        }
        return false
      }
    )
    return 'ADD_RULE'
  },
  addUntil: function* (next) {
    yield next('navigation/LOCATION_CHANGED')
    return 'RECREATE_RULE'
  },
  consequence: (_, { context }: any) => {
    const clickAction: WidgetClick = context.get('clickAction')
    const algoliaCookie = getCookieByName('_ALOGLIA')
    pushAa('convertedObjectIDsAfterSearch', {
      index: config.index.products,
      eventName: 'Add to basket',
      queryID: clickAction.meta.algoliaQueryID ?? '',
      ...(algoliaCookie && {
        userToken: algoliaCookie
      }),
      objectIDs: [clickAction.payload.sku]
    })
  }
})
