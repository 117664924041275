import * as React from 'react'
import styled from 'styled-components'
import BottomLine from './Components/BottomLine'
import theme from 'theme'
import { graphql, useStaticQuery } from 'gatsby'
import Newsletter from './Components/Newsletter'
import PaymentIcons from '../../molecules/PaymentIcons'
import BestShop from './Components/BestShop'
import { Gq } from './types'
import Catalogs from './Components/Catalogs'
import Content from './Components/Content'
import Copyright from './Components/Copyright'

export default function Footer() {
  /** @firescoutMockVar app-Footer.gq-appfooter */
  const gq: Gq = useStaticQuery(graphql`
    query appfooter {
      footer {
        aboutLinks {
          urlPath
          title
        }
        serviceLinks {
          urlPath
          title
        }
        facebook
        youtube
        instagram
        whatsapp
        pinterest
        linkedin
        xing
        legal {
          title
          urlPath
        }
        catalogImage
        catalogLink
        availablePayments
        newsletter {
          title
          iconSource
          disclaimer
          labels
        }
        advantages {
          label
          link
        }
        contactInfo {
          title
          value
          info
          type
        }
        trustIcon
        trustLink
        trustIcon2
        trustLink2
      }
      brandName: category(objectID: { eq: "Marken" }) {
        path
      }
    }
  `)
  const { footer } = gq
  return (
    <Wrapper data-cy-ctx="app/Footer">
      <BestShop />
      {footer.availablePayments && (
        <PaymentIcons
          headline={false}
          data-cy-state="available-payments-visible"
          iconList={footer.availablePayments}
        />
      )}
      {footer.newsletter.title && (
        <Newsletter footer={footer} data-cy-state="newsletter-visible" />
      )}
      {footer.catalogImage && footer.catalogLink && (
        <Catalogs footer={footer} data-cy-state="catalog-visible" />
      )}
      <Content footer={footer} />
      <Copyright footer={footer} />
      <BottomLine />
    </Wrapper>
  )
}

const Wrapper = styled.div`
  margin-top: ${theme.spacing('xl')};

  @media print {
    display: none;
  }
  > .Container {
    > div {
      margin-top: ${theme.spacing('l')};
      margin-bottom: ${theme.spacing('xl')};
    }
  }
`
