import * as t from '../../types'
import fetchFromAlgolia from 'utils/productListFetcher/bySwUuid'
import { Product } from 'utils/productListFetcher/types'
import { GetCartResponse } from 'hooks/account/response-types/get-cart'

export default async function transformCart(swCart: GetCartResponse) {
  const lineItems = swCart.lineItems

  const promoItems: t.PromotionItem[] = lineItems
    .filter((item) => item.type === 'promotion')
    .map((item) => ({
      reduction: item.price.totalPrice,
      code: item.referencedId,
      cartItemId: item.id,
      priceDefinitionType: item.priceDefinition?.type || '',
      priceDefinitionPercentage: item.priceDefinition?.percentage || 0,
      priceDefinitionAbsolute: item.priceDefinition?.price || 0
    }))

  let deliveryReduction = 0
  for (const item of swCart.lineItems) {
    if (item.type !== 'promotion') continue
    if (item.payload.discountScope !== 'delivery') continue
    deliveryReduction += parseFloat(item.payload.value)
  }
  const cart: t.Cart = {
    hash: '',
    specialDelivery: !!swCart.deliveries.find(
      (row) => row.shippingMethod.id === '10f0e51f044845d0b90ab6b9ffea9c52'
    ),
    promotionStatus: {
      added: !!swCart.lineItems.find((item) => item.type === 'promotion'),
      notEligable: !!swCart.errors['promotion-not-eligible'],
      excluded: !!swCart.errors['promotion-excluded']
    },
    dy: swCart.extensions.dynamic_yield,
    positionPrice: swCart.price.positionPrice,
    deliveryCost:
      swCart.deliveries
        .map((row) => row.shippingCosts.totalPrice)
        .reduce((prev, next) => prev + next, 0) + deliveryReduction,
    deliveryReduction: deliveryReduction,
    tax: swCart.price.calculatedTaxes[0]?.tax || 0,
    taxRate: swCart.price.calculatedTaxes[0]?.taxRate || 0,
    totalPrice: swCart.price.totalPrice,
    promotionItems: promoItems,
    items: await createFullProducts(
      lineItems
        .filter((item) => item.type === 'product')
        .map((item) => ({
          cartItemId: item.id,
          sw6Uuid: item.referencedId,
          quantity: item.quantity,
          sku: item.payload.productNumber,
          customTailor: item.payload.customProductData || false,
          additionalInformation: item.payload.additionalInformation || {
            list_positon: 0,
            item_list_name: '',
            item_list_id: ''
          },
          prices: {
            netPricePiece: item.price.unitPrice,
            netPriceTotal: item.price.unitPrice,
            grossPricePiece: item.price.unitPrice,
            grossPriceTotal: item.price.unitPrice
          }
        }))
    )
  }
  // implement better hashing
  cart.hash =
    cart.items.map((row) => row.quantity + row.cartItemId).join('') +
    cart.promotionItems.map((row) => row.code + row.cartItemId).join('')

  return cart
}

export async function createFullProducts(
  items: t.CartItemInitial[]
): Promise<t.CartItem[]> {
  if (items.length === 0) return []
  const products = await fetchFromAlgolia({
    sw6Uuids: items.map((row) => row.sw6Uuid),
    maxHits: 1000,
    noDistinct: true
  })(0)

  const productsById: Record<string, Product> = {}
  for (const product of products.data) productsById[product.sw6Uuid] = product

  const result: t.CartItem[] = []

  for (const item of items) {
    const product = productsById[item.sw6Uuid]

    if (!product) continue
    result.push({
      ...item,
      dyCategoryPathId: product.dyCategoryPathId,
      images: product.images,
      categories: product.categories,
      title: product.title,
      priceRules: product.priceRules,
      containerID: product.containerID,
      brand: product.brand,
      sellOut: product.sellOut,
      channelActive: product.channelActive,
      stock: product.stock,
      related: product.related,
      deliveryDays: product.deliveryDays,
      specialDelivery: product.specialDelivery,
      subtitle: product.subtitle,
      attributes: product.attributes,
      unit: product.unit,
      variantData: product.variantData,
      tracking: {
        packPriceNet: product.prices.packPriceNet,
        piecePriceNet: product.prices.piecePriceNet
      },
      prices: {
        ...product.prices,
        packPriceGross: item.prices.grossPriceTotal,
        packPriceNet: item.prices.netPriceTotal,
        piecePriceGross: item.prices.grossPricePiece,
        piecePriceNet: item.prices.netPricePiece
      },
      isMeterware: product.isMeterware,
      isMainVariant: product.isMainVariant,
      isPartnerItem: product.isPartnerItem
    })
  }

  return result
}
